<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="전력사용량"
        tableId="grid"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :usePaging="false"
        :hideBottom="false"
        :columnSetting="false"
        :filtering="false"
        :isExcelDown="false"
        :editable="editable"
        :gridHeightAuto="param.isFullScreen"
        @table-data-change="tableDataChange"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="저장" icon="save" v-if="!param.isFullScreen && editable" @btnClicked="saveData"/>
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-report-06',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: null,
        envWaterDailyResultId: '',
        isFullScreen: false,
        dailyResultDt: '',
        approvalStatusCd: null,
        approvalTarget: false,
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'envWaterMstWattmeterName',
            field: 'envWaterMstWattmeterName',
            label: '전력량계 명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'useTime',
            field: 'useTime',
            label: '가동시간',
            align: 'center',
            type: 'datetime',
            style: 'width:130px',
            minuteStep: 10,
            sortable: false,
          },
          {
            name: 'useAmount',
            field: 'useAmount',
            label: '사용량(㎾h)',
            align: 'center',
            type: 'number',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'usePerday',
            field: 'usePerday',
            label: '금일 폐수 1㎥당<br>소모전력량(㎾h/㎥)',
            align: 'center',
            type: 'number',
            style: 'width:140px',
            sortable: false,
          },
          {
            name: 'readTime',
            field: 'readTime',
            label: '검침시간',
            align: 'center',
            type: 'datetime',
            style: 'width:130px',
            minuteStep: 10,
            sortable: false,
          },
          {
            label: '전력량계 지침',
            align: 'center',
            child: [
              {
                name: 'prevScore',
                field: 'prevScore',
                label: '전일',
                align: 'center',
                style: 'width:130px',
                type: 'number',
                sortable: false,
              },
              {
                name: 'nowScore',
                field: 'nowScore',
                label: '금일',
                align: 'center',
                style: 'width:130px',
                type: 'number',
                sortable: false,
              },
              {
                name: 'wattmeterMagnification',
                field: 'wattmeterMagnification',
                label: '배율',
                align: 'center',
                style: 'width:130px',
                type: 'number',
                sortable: false,
              },
            ]
          },
          {
            name: 'remark',
            field: 'remark',
            label: '참고사항',
            align: 'left',
            type: 'text',
            style: 'width:200px',
            sortable: false,
          },
        ],
        data: [],
        height: '600px',
      },
      listUrl: '',
      saveUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  computed: {
    editable() {
      if (this.param.approvalTarget) {
        return true;
      } else if (this.param.approvalStatusCd !== null) {
        return false;
      } else {
        return this.$route.meta.editable
      }
    },
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.env.water.daily.wattmeter.list.url;
      this.saveUrl = transactionConfig.env.water.daily.wattmeter.save.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {envWaterDailyResultId: this.param.envWaterDailyResultId, plantCd: this.param.plantCd};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveData() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'U' || x.editFlag == 'C'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getList();
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '저장되었습니다.', 
                type: 'success', // success / info / warning / error
              });
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '변경된 데이터가 없습니다.',
          type: 'info', // success / info / warning / error
        });
      }
    },
    tableDataChange(props) {
      // if (props.row['prevScore'] > 0 && props.row['nowScore'] > 0) {
      //   this.$set(props.row, 'useAmount', props.row['nowScore'] - props.row['prevScore']);
      // } else if ((props.row['prevScore'] == 0 || props.row['prevScore'] === null) && props.row['nowScore'] > 0) {
      //   this.$set(props.row, 'useAmount', props.row['nowScore']);
      // }
      if (props.row['prevScore'] > 0 && props.row['nowScore'] > 0) {
        this.$set(props.row, 'useAmount', ((props.row['nowScore'] - props.row['prevScore']) * props.row['wattmeterMagnification']).toFixed(2));  // 배율 곱해서 계산
      } else if ((props.row['prevScore'] == 0 || props.row['prevScore'] === null) && props.row['nowScore'] > 0) {
        this.$set(props.row, 'useAmount', (props.row['nowScore'] * props.row['wattmeterMagnification']).toFixed(2)); // 배율 곱해서 계산
      }
    },
  }
};
</script>
